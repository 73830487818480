
import Vue from 'vue';

import ChooseTool from '../ChooseTool.vue';

import OrderBook from './OrderBook.vue';

export default Vue.extend({
    props: {
        placement: {
            type: String,
            default: '',
        },
        pair: {
            type: String,
            default: '',
        },
        isLast: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ChooseTool,
    },
    computed: {
        component() {
            return OrderBook;
        },
    },
});
