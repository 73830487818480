
import Vue from 'vue';

import ChooseTool from '../ChooseTool.vue';

import PriceChart from './PriceChart.vue';

export default Vue.extend({
    props: {
        placement: {
            type: String,
            default: '',
        },
        pair: {
            type: String,
            default: '',
        },
    },
    components: {
        ChooseTool,
    },
    computed: {
        component() {
            return PriceChart;
        },
    },
});
