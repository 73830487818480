
import Vue from 'vue';

import Icon from 'UI/Icon.vue';

interface Data {
    range: { start: Date, end: Date, };
    hidePopoverFunction: any;
}

interface Methods {
    onDatePickerInput: (data: any) => void;
    onDatePickerToggle: (toggle: any, hide: any) => void;
    generateDropdownString: (data: { start: Date, end: Date }) => string;
}

export default Vue.extend<Data, Methods, any, any>({
    components: {
        Icon,
    },
    props: {
        componentPath: {
            type: undefined,
            required: true,
        },
    },
    data() {
        const now = new Date();
        const monthAgo = new Date();
        monthAgo.setMonth(now.getMonth() - 1);
        return {
            range: { start: monthAgo, end: now },
            hidePopoverFunction: null,
        };
    },
    methods: {
        onDatePickerInput(data) {
            this.range = data;
        },
        onDatePickerToggle(toggle, hide) {
            toggle();
            this.hidePopoverFunction = hide;
        },
        generateDropdownString({ start, end }) {
            if (!!Date.parse(start.toString()) && !!Date.parse(end.toString())) {
                return `${this.$store.getters.getTimeDateString({ timestamp: start, showTime: false })} - ${this.$store.getters.getTimeDateString({ timestamp: end, showTime: false })}`;
            }
            return 'Period';
        },
    },
});
