
import Vue from 'vue';

import EmptyHeader from '../EmptyHeader.vue';

import BalanceByExchange from './BalanceByExchange.vue';

export default Vue.extend({
    components: {
        EmptyHeader,
    },
    computed: {
        component() {
            return BalanceByExchange;
        },
    },
});
