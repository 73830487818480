
import Vue from 'vue';

import AssetValue from 'UI/AssetValue.vue';
import Icon from 'UI/Icon.vue';
import theme from 'Theme';
import SettingsApi from 'Apis/Settings';
import FavoriteAssetPair from 'Entities/userSettings/FavoriteAssetPair';
import ApiError from 'Entities/ApiError';

export default Vue.extend<any, any, any, any>({
    components: {
        AssetValue,
        Icon,
    },
    props: {
        index: {
            type: Number,
        },
        source: {
            type: Object,
            default() {
                return {};
            },
        },
        setActiveAssetPair: {
            type: undefined,
            required: true,
        },
        currentAssetPair: {
            type: String,
            default: '',
        },
        currentPlacementName: {
            type: String,
            default: '',
        },
        favorites: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            theme,
        };
    },
    computed: {
        isFavorite() {
            return !!this.favorites
                .find(({ symbol, placementName }) => symbol === this.source.symbol
                    && placementName.toUpperCase() === this.currentPlacementName!.toUpperCase());
        },
    },
    methods: {
        async toggleFavorite() {
            if (this.isFavorite) {
                await this.removeFromFavorite();
                this.$scopedSlots.onRemoveFavorites(this.source.symbol);
                return;
            }
            await this.addToFavorite();
            this.$scopedSlots.onAddFavorites(this.source.symbol);
        },
        async addToFavorite() {
            try {
                await SettingsApi.addFavorite(new FavoriteAssetPair({
                    placementName: this.currentPlacementName!.toUpperCase(),
                    symbol: this.source.symbol,
                }));
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during adding tool to favorites');
                }
            }
        },
        async removeFromFavorite() {
            try {
                await SettingsApi.deleteFavorite(new FavoriteAssetPair({
                    placementName: this.currentPlacementName!.toUpperCase(),
                    symbol: this.source.symbol,
                }));
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during removing tool from favorites');
                }
            }
        },
    },
});
