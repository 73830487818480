
import Vue from 'vue';

type NoData = Record<string, never>;

type ModuleIconName = 'aggregated_balances' |
'asset_trades' |
'balance_by_day' |
'current_allocation' |
'current_balance' |
'exchange_trades' |
'order_book' |
'order_history' |
'payment' |
'place_order' |
'pnl_history' |
'price_chart' |
'quick_balances' |
'simple_aggregated_balances' |
'trade_history' |
'transfer' |
'transfer_history' |
'aggregated_balances_dark' |
'asset_trades_dark' |
'balance_by_day_dark' |
'current_allocation_dark' |
'current_balance_dark' |
'exchange_trades_dark' |
'order_book_dark' |
'order_history_dark' |
'payment_dark' |
'place_order_dark' |
'pnl_history_dark' |
'price_chart_dark' |
'quick_balances_dark' |
'simple_aggregated_balances_dark' |
'trade_history_dark' |
'transfer_dark' |
'transfer_history_dark';

export { ModuleIconName };

export default Vue.extend<NoData, NoData, NoData, NoData>({
    name: 'ModulesIcons',
});
