
import Vue from 'vue';

import EmptyHeader from '../EmptyHeader.vue';

import AgregatedBalances from './AgregatedBalances.vue';

export default Vue.extend({
    props: {
        page: {
            type: String,
            required: true,
        },
    },
    components: {
        EmptyHeader,
    },
    computed: {
        component() {
            return AgregatedBalances;
        },
    },
});
