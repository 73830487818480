
import Vue from 'vue';

import { ModuleIconName } from 'Components/Workspace/components/ModulesIcons.vue';

interface IconProps {
    icon: ModuleIconName;
    className?: string;
}

type NoData = Record<string, never>;

export { ModuleIconName };

export default Vue.extend<NoData, NoData, NoData, IconProps>({
    name: 'ModuleIcon',
    props: {
        icon: String as any,
        className: String,
    },
});
