
import Vue from 'vue';

import theme from 'Theme';

export default Vue.extend<any, any, any, any>({
    props: {
        componentPath: {
            type: undefined,
            required: true,
        },
        showShortGraph: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            theme,
        };
    },
});
