
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { getPlacements, setActivePlacement, RESTRICTED_PLACEMENTS } from 'Store/v2/WorkspaceChooseTool';
import Placement from 'Entities/publicPresenter/Placement';
import TradingData from 'Entities/publicPresenter/TradingData';
import { getNewWorkspaceSpotAssetPairs } from 'Store/v2/AssetPairs';
import ChooseToolItem from 'Modules/ChooseTool.Item.vue';
import Icon from 'UI/Icon.vue';
import theme from 'Theme';
import Search from 'Control/Search.vue';
import HeaderSwitcher from 'Control/HeaderSwitcher.vue';
import SettingsApi from 'Apis/Settings';
import GetFavoriteAssetPairsRequest from 'Entities/userSettings/GetFavoriteAssetPairsRequest';
import FavoriteAssetPair from 'Entities/userSettings/FavoriteAssetPair';
import { RELEASED_PLATFORMS } from 'Store/modules/Placements';
import { SET_PLACEMENT_NAME } from 'Store/v2/LinkPlacement';

interface Data {
    showSelect: boolean;
    currentPlacementName: string;
    currentAssetPair: string;
    currentPlacementAssetPairs: TradingData[];
    searchedAssetPair: string;
    isSearchActive: boolean;
    sortedPlacementAssetPairs: TradingData[];
    moduleId: string;
    selectId: string;
    clickListner: (event: any) => void;
    favoritesIndex: number;
    favorites: FavoriteAssetPair[];
}
interface Computed {
    placementsList: Placement[];
    sortedPlacementsList: Placement[];
    favoritesAssetPairs: TradingData[];
    currentCollection: TradingData[];
    activePlacements: Map<string, TradingData[]>;
    activePlacementsSize: number;
}

interface Methods {
    isPairFavorite: (symbol: string) => boolean;
    toggleSelect: () => void;
    setActivePlacement: (data: string) => void;
    setActiveAssetPair: (data: string) => void;
    closeSelect: () => void;
    getIsPlacementAvailable: (placementName: string) => boolean;
    getIsPlacementLinked: (placementName: string) => boolean;
    linkPlacement: (placementName: string) => void;
    getFavorites: () => void;
    onAddFavorites: (symbol: string) => void;
    onRemoveFavorites: (symbol: string) => void;
    getPlacementAssetPairs: (placementName: string) => void;
    getIsPlacementPending: (data: string) => boolean;
}

export default Vue.extend<Data, Methods, Computed, any>({
    components: {
        HeaderSwitcher,
        ChooseToolItem,
        Icon,
        Search,
    },
    props: {
        componentPath: {
            type: undefined,
            required: true,
        },
        placement: {
            type: String,
            default: '',
        },
        pair: {
            type: String,
            default: '',
        },
        isLast: {
            type: Boolean,
            default: false,
        },
        uniqueId: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            ChooseToolItem,
            showSelect: false,
            currentPlacementName: '',
            currentAssetPair: '',
            currentPlacementAssetPairs: [],
            searchedAssetPair: '',
            isSearchActive: false,
            sortedPlacementAssetPairs: [],
            moduleId: 'id',
            selectId: 'id',
            favoritesIndex: 0,
            favorites: [],
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            clickListner: () => {},
            theme,

            RESTRICTED_PLACEMENTS,
        };
    },
    computed: {
        ...mapGetters({
            activeAccountId: 'Accounts/activeAccountID',
        }),
        currentCollection() {
            if (this.favoritesIndex === 1) {
                return this.favoritesAssetPairs;
            }
            return this.isSearchActive ? this.sortedPlacementAssetPairs : this.currentPlacementAssetPairs;
        },
        placementsList() {
            return this.$store.state.WorkspaceChooseTool.placements ?? [];
        },
        sortedPlacementsList() {
            const { placementsList } = this;
            return placementsList
                .filter(({ name }) => RELEASED_PLATFORMS[name])
                .sort(({ name: nameA }, { name: nameB }) => {
                    if (RESTRICTED_PLACEMENTS[nameA] && !RESTRICTED_PLACEMENTS[nameB]) {
                        return 1;
                    }
                    if (!RESTRICTED_PLACEMENTS[nameA] && RESTRICTED_PLACEMENTS[nameB]) {
                        return -1;
                    }
                    return 0;
                });
        },
        favoritesAssetPairs() {
            return this.currentPlacementAssetPairs.filter((pair) => this.isPairFavorite(pair.symbol!));
        },
        activePlacementsSize() {
            return this.$store.state.WorkspaceChooseTool.activePlacements.size;
        },
        activePlacements() {
            return this.$store.state.WorkspaceChooseTool.activePlacements;
        },
    },
    methods: {
        onAddFavorites(symbol) {
            this.favorites.push(new FavoriteAssetPair({
                symbol,
                placementName: this.placement,
            }));
        },
        onRemoveFavorites(symbol) {
            const index = this.favorites.findIndex(({ symbol: s }) => s === symbol);
            this.favorites.splice(index, 1);
        },
        isPairFavorite(symbol) {
            return this.favorites.some(({ symbol: s }) => s === symbol);
        },
        toggleSelect() {
            if (!this.showSelect) {
                setTimeout(() => {
                    this.showSelect = !this.showSelect;
                }, 100);
                return;
            }
            this.showSelect = !this.showSelect;
        },
        closeSelect() {
            this.showSelect = false;
        },
        async setActivePlacement(placementName) {
            if (RESTRICTED_PLACEMENTS[placementName] || !this.getIsPlacementAvailable(placementName)) {
                return;
            }
            this.currentPlacementName = placementName.toUpperCase();
        },
        setActiveAssetPair(assetPairSymbol) {
            this.currentAssetPair = assetPairSymbol;
            this.showSelect = false;
        },
        getIsPlacementAvailable(placementName) {
            return this.$store.state.Placements.maintenanceStatuses.get(placementName);
        },
        getIsPlacementLinked(placementName: string) {
            return this.$store.getters['Accounts/isPlacementLinkedToActiveAccount'](placementName);
        },
        getIsPlacementPending(placementName: string) {
            return this.$store.getters['Accounts/isPlacementPending'](placementName);
        },
        async linkPlacement(placementName) {
            const placement = this.$store.state.Placements.placements.find((p) => p.name.toLowerCase() === placementName.toLowerCase());
            if (placement && placement.needExternalKyc) {
                this.showSelect = false;
                this.$store.commit(SET_PLACEMENT_NAME(placementName));
                this.$modal.show('confirmLinkModal');
            } else {
                await this.$store.dispatch('Accounts/linkPlacement', placementName);
            }
        },
        async getFavorites() {
            try {
                const { data: favorites } = await SettingsApi.getFavorites(new GetFavoriteAssetPairsRequest({
                    placementName: this.placement,
                }));
                this.favorites = favorites;
            } catch {
                this.favorites = [];
            }
        },
        async getPlacementAssetPairs(placementName) {
            const realPlacementName = this.sortedPlacementsList.find(({ name }) => name.toUpperCase() === placementName.toUpperCase())?.name;
            if (realPlacementName) {
                try {
                    await this.$store.dispatch(setActivePlacement(realPlacementName));
                } catch {
                    // code crushed because of network error
                }

                const placementAssetPairsData = this.$store.state.WorkspaceChooseTool.activePlacements;
                if (placementAssetPairsData.has(realPlacementName.toUpperCase())) {
                    this.currentPlacementAssetPairs = placementAssetPairsData.get(realPlacementName.toUpperCase())!;
                }
            }
        },
    },
    created() {
        // generate module id
        this.moduleId = `id${Math.round(Math.random() * 100000)}`;
        this.selectId = `id${Math.round(Math.random() * 100000)}`;
        this.clickListner = (event) => {
            const container = document.getElementById(this.moduleId);
            const subContainer = document.getElementById(this.selectId);
            if (!event.target) {
                return;
            }
            if (!container?.contains(event.target as Node) && !subContainer?.contains(event.target as Node)) {
                this.closeSelect();
            }
        };
    },
    async mounted() {
        await this.$store.dispatch(getPlacements(undefined));
        await this.$store.dispatch(setActivePlacement(this.sortedPlacementsList[0].name));
        if (this.currentPlacementName) {
            await this.$store.dispatch(getNewWorkspaceSpotAssetPairs(this.currentPlacementName));
            await this.getPlacementAssetPairs(this.currentPlacementName);
        }
        this.currentPlacementName = this.sortedPlacementsList[0].name.toUpperCase();
        if (this.placement) {
            this.currentPlacementName = this.placement;

            await this.getFavorites();
        }
        if (this.pair) {
            this.currentAssetPair = this.pair;
        }

        // hide dropdown on click outside
        document.addEventListener('click', this.clickListner);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.clickListner);
    },
    watch: {
        activePlacements() {
            if (this.activePlacements.has(this.currentPlacementName)) {
                this.currentPlacementAssetPairs = this.activePlacements.get(this.currentPlacementName)!;
            }
        },
        pair(val) {
            if (val) {
                this.currentAssetPair = val;
            }
        },
        placement(val) {
            if (val) {
                this.currentPlacementName = val;

                this.getFavorites();
            }
        },
        async currentPlacementName(value) {
            const val = value.toUpperCase();
            this.$emit('change-placement', val);

            await this.getPlacementAssetPairs(val);

            if (val) {
                try {
                    await this.$store.dispatch(getNewWorkspaceSpotAssetPairs(val));
                } catch {
                    // code crushed because of network error
                }
            }
        },
        currentAssetPair(val) {
            this.$emit('change-pair', val);
        },
        currentPlacementAssetPairs(newAssetPairsArray) {
            const index = newAssetPairsArray.findIndex((pair) => pair.symbol === this.currentAssetPair);
            if (typeof index === 'number' && index === -1) {
                this.currentAssetPair = newAssetPairsArray[0]?.symbol ?? '';
            }
        },
        searchedAssetPair(val) {
            if (val.length < 3) {
                this.isSearchActive = false;
                return;
            }
            this.isSearchActive = true;
            this.sortedPlacementAssetPairs = this.currentPlacementAssetPairs.filter((pair) => pair.symbol!.toLowerCase().indexOf(val.toLowerCase()) !== -1);
        },
    },
});
