
import Vue from 'vue';

import EmptyHeader from '../EmptyHeader.vue';

import AccountPortfolio from './AccountPortfolio.vue';

export default Vue.extend({
    components: {
        EmptyHeader,
    },
    computed: {
        component() {
            return AccountPortfolio;
        },
    },
});
