
import Vue from 'vue';

import EmptyHeader from '../EmptyHeader.vue';

import Payment from './Payment.vue';

export default Vue.extend({
    components: {
        EmptyHeader,
    },
    computed: {
        component() {
            return Payment;
        },
    },
});
