
import Vue from 'vue';
import { nanoid } from 'nanoid';

import TakeProfitStopLossModal from 'Modules/OrdersHistory/components/TakeProfitStopLossModal.vue';

import ChooseTool from '../ChooseTool.vue';

import Trading from './Trading.vue';
import Modal from './MultiaccountOrdersModal/Modal.vue';

export default Vue.extend({
    props: {
        placement: {
            type: String,
            default: '',
        },
        pair: {
            type: String,
            default: '',
        },
    },
    components: {
        TakeProfitStopLossModal,
        ChooseTool,
        Modal,
    },
    data() {
        return {
            uniqueId: '',
        };
    },
    computed: {
        component() {
            return Trading;
        },
    },
    mounted() {
        this.uniqueId = nanoid(8);
    },
});
