
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { gridPages } from 'Models/interface';
import layoutNameResolver from 'Mixins/layoutNameResolver';
import PageLayout from 'Common/PageLayout.vue';
import OrderBookModule from 'Modules/OrderBook/OrderBook.Module.vue';
import AgregatedBalancesModule from 'Modules/AgregatedBalances/AgregatedBalances.Module.vue';
import PaymentModule from 'Modules/Payment/Payment.Module.vue';
import TransferModule from 'Modules/Transfer/Transfer.Module.vue';
import TransferHistoryModule from 'Modules/TransferHistory/TransferHistory.Module.vue';
import PriceChart from 'Modules/PriceChart/PriceChart.Module.vue';
import TradeHistory from 'Modules/TradeHistory/TradeHistory.Module.vue';
import AccountPortfolio from 'Modules/AccountPortfolio/AccountPortfolio.Module.vue';
import BalanceByCurrency from 'Modules/BalanceByCurrency/BalanceByCurrency.Module.vue';
import BalanceByExchange from 'Modules/BalanceByExchange/BalanceByExchange.Module.vue';
import BalanceByPeriod from 'Modules/BalanceByPeriod/BalanceByPeriod.Module.vue';
import TradesPerAssetPair from 'Modules/TradesPerAssetPairDonut/TradesPerAssetPairDonut.Module.vue';
import TradesPerExchange from 'Modules/TradesPerExchangeDonut/TradesPerExchangeDonut.Module.vue';
import PNLChart from 'Modules/PNLChart/PNLChart.Module.vue';
import Trading from 'Modules/Trading/Trading.Module.vue';
import OrderHistory from 'Modules/OrdersHistory/OrdersHistory.Module.vue';
import Preset from 'Entities/userSettings/Preset';
import { doPreset, getActivePresets, PRESET_TYPES_TO_LAYOUTS_NAMES, PRESETS_TYPES } from 'Store/v2/Presets';
import SettingsApi from 'Apis/Settings';
import PresetRequest from 'Entities/userSettings/PresetRequest';
import router from '@/router';
import Dropdown from 'Control/Dropdown.vue';
import Icon from 'UI/Icon.vue';
import Button from 'Control/Button.vue';
import ModulesIcons from 'Components/Workspace/components/ModulesIcons.vue';
import ModuleIcon from 'Components/Workspace/components/ModuleIcon.vue';
import AccountColorMarker from 'Common/AccountColorMarker.vue';
import Account from 'Entities/privatePresenter/Account';
import { getFavoriteAssetPairsList } from 'Store/v2/AssetPairs';
import Position from 'Components/Earn/components/Position.vue';
import { composedPath } from 'Lib/utils/eventPathChecker';
import UpdatePresetRequest from 'Entities/userSettings/UpdatePresetRequest';
import ApiError from 'Entities/ApiError';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';

enum ModulesTypes {
    TERMINAL = 'Terminal',
    PORTFOLIO = 'Portfolio',
    WALLET = 'Wallet',
}

interface IAvailableModule {
    name: string;
    imgLight: any;
    imgDark: any;
    props?: any;
    component: any;
    type: string;
    isSelected: boolean;
    count: number;
    maxCount: number,
    w: number;
    h: number;
    additionalStyle?: Record<string, string>;
}

interface IShownModulePosition {
    x: number;
    y: number;
    w: number;
    h: number;
    placement?: string;
    pair?: string;
}

interface Data {
    currentBreakpoint: string;
    availableModules: IAvailableModule[];
    timeoutId: null | ReturnType<typeof setTimeout>;
    isBlocksSelecting: boolean;
    isBlockRemoving: boolean;
    isNew: boolean;
    shownModules: string[];
    shownModulesPositions: IShownModulePosition[];
    showTooltip: boolean;
    presetedPlacementAndPairs: null | Map<number, { placement: string, pair: string }>
    activePresetIndex: number;
    showPresetsDropdown: boolean;
}

interface Methods {
    getPageByName: (data: string) => string;
    selectModule: (data: string) => void;
    changeModuleCount: (data: { moduleName: string, isIncrease: boolean }) => void;
    clearData: () => void;
    addModules: (data?: boolean) => void;
    getSize: (data: string) => { w: number, h: number };
    getModuleComponent: (data: string) => any;
    removeModule: (data: number) => void;
    toggleIsSelecting: () => void;
    toggleIsRemoving: () => void;
    startResettingProcess: () => void;
    reset: () => void;
    clearTimeoutId: () => void;
    setPlacement: (placementName: string, index: number) => void;
    setPair: (pairSymbol: string, index: number) => void;
    updateShownModules: () => void;
    getPlacementAndPairByIndex: (data: number) => { placement: string, pair: string };
    openNewWorkspaceWindow: () => void;
    getGridItemCoordinates: (data: number) => { x: number, y: number };
    toggleTooltip: () => void;
    selectPreset: (data: Preset) => void;
    setPresetIndex: (data: number) => void;
    isModuleSelectable: (data: string) => boolean;
    getResizableItemSize: (data: number) => { w: number, h: number };
    togglePresetsDropdown: () => void;
    closePresetsDropdown: (data: any) => void;
    saveCurrentPreset: () => void;
    openPresetsListModal: () => void;
    openSavePresetModal: () => void;
}

interface Computed {
    activeAccountName: string;
    vueGridLayoutBreakpoints: any;
    vueGridLayoutCols: any;
    layout: any;
    terminalModules: IAvailableModule[];
    portfolioModules: IAvailableModule[];
    walletModules: IAvailableModule[];
    activePreset: undefined | string;
    shownModulesLength: number;
    fullPresetsList: Preset[];
    isPresetSaved: boolean;
    presetsList: undefined | Preset[];
    isNewQueryParam: string | (string | null)[];
    presetQueryParam: string | (string | null)[];
    newPresetWindowName: string;
    activeAccount: Account;
    activeAccountColor: string;
    isThemeDark: boolean;
}

export default Vue.extend<Data, Methods, Computed>({
    components: {
        Position,
        OrderBookModule,
        PageLayout,
        Dropdown,
        Icon,
        Button,
        ModulesIcons,
        ModuleIcon,
        AccountColorMarker,
    },
    mixins: [layoutNameResolver],
    data() {
        return {
            currentBreakpoint: 'lg',
            timeoutId: null,
            presetedPlacementAndPairs: null,
            isBlocksSelecting: false,
            isBlockRemoving: false,
            showTooltip: false,
            isNew: false,
            showPresetsDropdown: false,
            availableModules: [
                {
                    type: ModulesTypes.TERMINAL,
                    name: 'Price Chart',
                    imgLight: 'price_chart',
                    imgDark: 'price_chart_dark',
                    component: PriceChart,
                    isSelected: false,
                    count: 0,
                    maxCount: 10,
                    w: 2,
                    h: 6,
                },
                {
                    type: ModulesTypes.TERMINAL,
                    name: 'Order Book',
                    imgLight: 'order_book',
                    imgDark: 'order_book_dark',
                    component: OrderBookModule,
                    isSelected: false,
                    count: 0,
                    maxCount: 10,
                    w: 1,
                    h: 6,
                },
                {
                    type: ModulesTypes.TERMINAL,
                    name: 'Trade History',
                    imgLight: 'trade_history',
                    imgDark: 'trade_history_dark',
                    component: TradeHistory,
                    isSelected: false,
                    count: 0,
                    maxCount: 10,
                    w: 1,
                    h: 6,
                },
                {
                    type: ModulesTypes.TERMINAL,
                    name: 'Balance Overview',
                    imgLight: 'quick_balances',
                    imgDark: 'quick_balances_dark',
                    component: AccountPortfolio,
                    isSelected: false,
                    count: 0,
                    maxCount: 1,
                    w: 2,
                    h: 3,
                },
                {
                    type: ModulesTypes.TERMINAL,
                    name: 'Place Order',
                    imgLight: 'place_order',
                    imgDark: 'place_order_dark',
                    component: Trading,
                    isSelected: false,
                    count: 0,
                    maxCount: 10,
                    w: 2,
                    h: 3,
                },
                {
                    type: ModulesTypes.TERMINAL,
                    name: 'Order List',
                    imgLight: 'order_history',
                    imgDark: 'order_history_dark',
                    component: OrderHistory,
                    isSelected: false,
                    count: 0,
                    maxCount: 1,
                    w: 4,
                    h: 6,
                },
                {
                    type: ModulesTypes.PORTFOLIO,
                    name: 'Current Balance',
                    imgLight: 'circle',
                    imgDark: 'circle_dark',
                    component: BalanceByCurrency,
                    isSelected: false,
                    count: 0,
                    maxCount: 1,
                    w: 1,
                    h: 3,
                },
                {
                    type: ModulesTypes.PORTFOLIO,
                    name: 'Current Allocation',
                    imgLight: 'circle',
                    imgDark: 'circle_dark',
                    component: BalanceByExchange,
                    isSelected: false,
                    count: 0,
                    maxCount: 1,
                    w: 1,
                    h: 3,
                    additionalStyle: { transform: 'rotate(30deg)' },
                },
                {
                    type: ModulesTypes.PORTFOLIO,
                    name: 'Asset Trades',
                    imgLight: 'circle',
                    imgDark: 'circle_dark',
                    component: TradesPerAssetPair,
                    isSelected: false,
                    count: 0,
                    maxCount: 10,
                    w: 1,
                    h: 3,
                    additionalStyle: { transform: 'rotate(85deg)' },
                },
                {
                    type: ModulesTypes.PORTFOLIO,
                    name: 'Exchange Trades',
                    imgLight: 'circle',
                    imgDark: 'circle_dark',
                    component: TradesPerExchange,
                    isSelected: false,
                    count: 0,
                    maxCount: 10,
                    w: 1,
                    h: 3,
                    additionalStyle: { transform: 'rotate(110deg)' },
                },
                {
                    type: ModulesTypes.PORTFOLIO,
                    name: 'Balance by Day',
                    imgLight: 'balance_by_day',
                    imgDark: 'balance_by_day_dark',
                    component: BalanceByPeriod,
                    isSelected: false,
                    count: 0,
                    maxCount: 10,
                    w: 2,
                    h: 6,
                },
                {
                    type: ModulesTypes.PORTFOLIO,
                    name: 'P&L History',
                    imgLight: 'pnl_history',
                    imgDark: 'pnl_history_dark',
                    component: PNLChart,
                    isSelected: false,
                    count: 0,
                    maxCount: 10,
                    w: 2,
                    h: 6,
                },
                {
                    type: ModulesTypes.PORTFOLIO,
                    name: 'Aggregated Balance',
                    props: {
                        showShortGraph: true,
                    },
                    imgLight: 'aggregated_balances',
                    imgDark: 'aggregated_balances_dark',
                    component: AgregatedBalancesModule,
                    isSelected: false,
                    count: 0,
                    maxCount: 1,
                    w: 4,
                    h: 6,
                },
                {
                    type: ModulesTypes.WALLET,
                    name: 'Transfers',
                    imgLight: 'transfer',
                    imgDark: 'transfer_dark',
                    component: TransferModule,
                    isSelected: false,
                    count: 0,
                    maxCount: 1,
                    w: 2,
                    h: 3,
                },
                {
                    type: ModulesTypes.WALLET,
                    name: 'Transfer History',
                    imgLight: 'transfer_history',
                    imgDark: 'transfer_history_dark',
                    component: TransferHistoryModule,
                    isSelected: false,
                    count: 0,
                    maxCount: 1,
                    w: 2,
                    h: 6,
                },
                {
                    type: ModulesTypes.WALLET,
                    name: 'Deposit/Withdraw',
                    imgLight: 'payment',
                    imgDark: 'payment_dark',
                    component: PaymentModule,
                    isSelected: false,
                    count: 0,
                    maxCount: 1,
                    w: 2,
                    h: 3,
                },
                {
                    type: ModulesTypes.WALLET,
                    name: 'Simplified Aggregated Balance',
                    imgLight: 'aggregated_balances',
                    imgDark: 'aggregated_balances_dark',
                    component: AgregatedBalancesModule,
                    isSelected: false,
                    count: 0,
                    maxCount: 1,
                    w: 4,
                    h: 6,
                },
            ],
            shownModules: [],
            shownModulesPositions: [],
            activePresetIndex: 0,
        };
    },
    computed: {
        ...mapGetters({
            activeAccountName: 'Accounts/activeAccountName',
            vueGridLayoutBreakpoints: 'vueGridLayoutBreakpoints',
            vueGridLayoutCols: 'vueGridLayoutCols',
            isThemeDark: 'isThemeDark',
            activeAccount: 'Accounts/activeAccount',
        }),
        layout: {
            get() {
                this.updateShownModules();
                return this.isNew ? this.$store.getters.defaultPageGrid(gridPages.WORKSPACE, 'lg') : this.$store.getters.pageGrid(gridPages.WORKSPACE, 'lg');
            },
            set(grid) {
                if (this.isNew) {
                    this.$store.dispatch('changePageGrid', {
                        page: gridPages.WORKSPACE,
                        grid,
                        breakpoint: 'lg',
                    });
                } else {
                    this.$store.dispatch('changeDefaultPageGrid', {
                        page: gridPages.WORKSPACE,
                        grid,
                        breakpoint: 'lg',
                    });
                }
                this.updateShownModules();
            },
        },
        isPresetSaved() {
            const activePreset = this.$store.state.Presets.activePresets?.find(({ type, name }) => name === this.activePreset && type === 'WORKSPACE');
            if (!activePreset) {
                return false;
            }
            const { data } = activePreset;
            const currentData = this.isNew ? this.$store.getters.defaultPageGrid(gridPages.WORKSPACE, 'lg') : this.$store.getters.pageGrid(gridPages.WORKSPACE, 'lg');
            if ((data as any)?.length !== currentData.length) {
                return false;
            }
            const dataMap = new Map<number, any>();
            const currentDataMap = new Map<number, any>();
            (data as any).forEach((el) => {
                dataMap.set(el.i, el);
            });
            currentData.forEach((el) => {
                currentDataMap.set(el.i, el);
            });
            let flag = true;
            Array.from(dataMap.keys()).forEach((i) => {
                if (dataMap.get(i).x !== currentDataMap.get(i).x
                    || dataMap.get(i).y !== currentDataMap.get(i).y
                    || dataMap.get(i).w !== currentDataMap.get(i).w
                    || dataMap.get(i).h !== currentDataMap.get(i).h
                    || dataMap.get(i).pair !== currentDataMap.get(i).pair
                    || dataMap.get(i).placement !== currentDataMap.get(i).placement) {
                    flag = false;
                }
            });
            return flag;
        },
        terminalModules() {
            return this.availableModules.filter(({ type }) => type === ModulesTypes.TERMINAL);
        },
        portfolioModules() {
            return this.availableModules.filter(({ type }) => type === ModulesTypes.PORTFOLIO);
        },
        walletModules() {
            return this.availableModules.filter(({ type }) => type === ModulesTypes.WALLET);
        },
        presetsList() {
            return this.$store.state.Presets.presetsList;
        },
        activePreset() {
            if (!this.presetsList) {
                return undefined;
            }
            return this.presetsList.find(({ type, active }) => type === 'WORKSPACE' && active)?.name;
        },
        shownModulesLength() {
            return this.shownModules.length;
        },
        fullPresetsList() {
            const { presetsList, defaultPresetsList } = this.$store.state.Presets;
            if (!presetsList && !defaultPresetsList) {
                return [];
            }
            return [...defaultPresetsList || [], ...presetsList || []].filter(({ type }) => type === 'WORKSPACE');
        },
        isNewQueryParam() {
            return this.$route.query.isNew;
        },
        presetQueryParam() {
            return this.$route.query.preset;
        },
        newPresetWindowName() {
            return this.fullPresetsList.find(({ id }) => id === Number(this.presetQueryParam))?.name ?? '';
        },
        activeAccountColor() {
            if (!this.activeAccount || !this.activeAccount.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return this.activeAccount.color;
        },
    },
    methods: {
        openPresetsListModal() {
            this.showPresetsDropdown = false;
            this.$modal.show('presetsListModal');
        },
        openSavePresetModal() {
            this.showPresetsDropdown = false;
            this.$modal.show('savePresetModal');
        },
        async saveCurrentPreset() {
            const selectedPreset = this.fullPresetsList[this.activePresetIndex];
            if (selectedPreset.id !== undefined && !this.isPresetSaved) {
                try {
                    this.$store.commit(SET_LOADING_ON(undefined));
                    await SettingsApi.updatePreset(new UpdatePresetRequest({
                        data: this.$store.state.Interface.pageGrids.workspace.lg,
                        id: selectedPreset.id,
                        name: selectedPreset.name!,
                        type: 'WORKSPACE',
                    }));
                    await this.$store.dispatch(getActivePresets(undefined));
                    await this.$store.dispatch('Notificator/showSuccessNotification', 'Preset has been successfully saved');
                } catch (error) {
                    if (error instanceof ApiError) {
                        await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong');
                    }
                } finally {
                    this.showPresetsDropdown = false;
                    this.$store.commit(SET_LOADING_OFF(undefined));
                }
            }
        },
        togglePresetsDropdown() {
            this.showPresetsDropdown = !this.showPresetsDropdown;
        },
        getPageByName(module) {
            return this.availableModules.find(({ name }) => name === module)?.type ?? '';
        },
        openNewWorkspaceWindow() {
            const routeData = this.$router.resolve({ path: '/workspace', query: { isNew: 'true' } });
            window.open(routeData.href, '', 'popup=true,left=0,top=0');
        },
        updateShownModules() {
            this.shownModules = [];
            const grid = this.isNew ? this.$store.getters.defaultPageGrid(gridPages.WORKSPACE, 'lg') : this.$store.getters.pageGrid(gridPages.WORKSPACE, 'lg');
            this.shownModules = grid
                .sort(({ i: iOne }, { i: iTwo }) => iOne - iTwo)
                .map(({ name }) => name);
            this.presetedPlacementAndPairs = null;
            grid.forEach(({ i, placement, pair }) => {
                if (placement || pair) {
                    if (!this.presetedPlacementAndPairs) {
                        this.presetedPlacementAndPairs = new Map<number, { placement: string, pair: string }>();
                    }
                    this.presetedPlacementAndPairs.set(i, { placement, pair });
                }
            });
            if (this.isBlocksSelecting) {
                this.isBlockRemoving = false;
            }
        },
        getPlacementAndPairByIndex(index) {
            if (this.presetedPlacementAndPairs && this.presetedPlacementAndPairs.has(index)) {
                return this.presetedPlacementAndPairs.get(index) ?? { placement: '', pair: '' };
            }
            return { placement: '', pair: '' };
        },
        selectModule(moduleName) {
            if (!this.isModuleSelectable(moduleName)) {
                return;
            }
            const index = this.availableModules.findIndex(({ name }) => name === moduleName);
            if (index === -1) {
                return;
            }
            const { isSelected } = this.availableModules[index];
            this.availableModules[index].isSelected = !isSelected;
            if (this.availableModules[index].isSelected) {
                this.availableModules[index].count = 1;
            } else {
                this.availableModules[index].count = 0;
            }
        },
        isModuleSelectable(moduleName) {
            const { maxCount } = this.availableModules.find(({ name }) => name === moduleName) ?? { maxCount: 0 };
            const moduleCount = this.shownModules.reduce((accum, current) => {
                if (current === moduleName) {
                    return accum + 1;
                }
                return accum;
            }, 0);
            return moduleCount < maxCount;
        },
        changeModuleCount({ moduleName, isIncrease }) {
            const index = this.availableModules.findIndex(({ name }) => name === moduleName);
            const alreadyCreatedModulesCount = this.shownModules.reduce((accum, current) => {
                if (current === moduleName) {
                    return accum + 1;
                }
                return accum;
            }, 0);
            if (index === -1) {
                return;
            }
            let { count } = this.availableModules[index];
            if (isIncrease) {
                count += 1;
                if (count + alreadyCreatedModulesCount > this.availableModules[index].maxCount) {
                    count -= 1;
                }
            } else {
                count -= 1;
                if (count < 0) {
                    count = 0;
                }
            }
            this.availableModules[index].count = count;
        },
        clearData() {
            this.availableModules = this.availableModules.map((data) => {
                return {
                    ...data,
                    count: 0,
                    isSelected: false,
                };
            });
        },
        async addModules(recalculate = false) {
            const result: string[] = [];
            this.availableModules.forEach(({ name, count }) => {
                if (count !== 0) {
                    for (let i = 0; i < count; i += 1) {
                        result.push(name);
                    }
                }
            });
            if (result.length === 0 && !recalculate) {
                return;
            }
            this.shownModules = [...this.shownModules, ...result];
            this.isBlocksSelecting = false;
            this.shownModulesPositions = [];
            await this.$store.dispatch(`${this.isNew ? 'changeDefaultPageGrid' : 'changePageGrid'}`, {
                page: gridPages.WORKSPACE,
                grid: this.shownModules.map((m, i) => {
                    if (i === 0) {
                        this.shownModulesPositions.push({
                            w: this.getSize(m).w,
                            h: this.getSize(m).h,
                            x: 0,
                            y: 0,
                            placement: this.getPlacementAndPairByIndex(i).placement,
                            pair: this.getPlacementAndPairByIndex(i).pair,
                        });
                        return {
                            i,
                            w: this.getSize(m).w,
                            h: this.getSize(m).h,
                            x: 0,
                            y: 0,
                            name: m,
                            placement: this.getPlacementAndPairByIndex(i).placement,
                            pair: this.getPlacementAndPairByIndex(i).pair,
                        };
                    }
                    let x: number;
                    let y: number;
                    const prevModule = this.shownModulesPositions[i - 1];
                    const currentRowModules = this.shownModulesPositions.filter(({ y }) => y === prevModule.y);
                    const currentRowWidth = currentRowModules.reduce((prev, next) => prev + next.w, 0);
                    if (currentRowWidth + this.getSize(m).w > 4) {
                        x = 0;
                        let currentRowMaxHeight = 0;
                        currentRowModules.forEach(({ h }) => {
                            if (h > currentRowMaxHeight) {
                                currentRowMaxHeight = h;
                            }
                        });
                        y = currentRowMaxHeight + prevModule.y;
                    } else {
                        x = currentRowWidth;
                        y = prevModule.y;
                    }
                    this.shownModulesPositions.push({
                        w: this.getSize(m).w,
                        h: this.getSize(m).h,
                        x,
                        y,
                        placement: this.getPlacementAndPairByIndex(i).placement,
                        pair: this.getPlacementAndPairByIndex(i).pair,
                    });
                    return {
                        i,
                        w: this.getSize(m).w,
                        h: this.getSize(m).h,
                        x,
                        y,
                        name: m,
                        placement: this.getPlacementAndPairByIndex(i).placement,
                        pair: this.getPlacementAndPairByIndex(i).pair,
                    };
                }),
                breakpoint: 'lg',
            });
        },
        getSize(moduleName) {
            const module = this.availableModules.find(({ name }) => name === moduleName);
            return {
                w: this.currentBreakpoint === 'md' ? module!.w * 2 : module!.w,
                h: module!.h,
            };
        },
        getModuleComponent(module) {
            const moduleData = this.availableModules.find(({ name }) => name === module);
            return moduleData!.component;
        },
        removeModule(index) {
            this.shownModules.splice(index, 1);
            const foundIndex = this.layout.findIndex(({ i }) => i === index);
            if (foundIndex !== -1) {
                this.layout.splice(foundIndex, 1);
                this.layout.forEach((module, ind) => {
                    if (ind >= foundIndex) {
                        module.i -= 1;
                    }
                });
            }
        },
        toggleIsSelecting() {
            if (this.shownModules.length === 0 || this.isBlockRemoving) {
                return;
            }
            this.isBlocksSelecting = !this.isBlocksSelecting;
            this.isBlockRemoving = false;
        },
        toggleIsRemoving() {
            if (this.shownModules.length === 0 || this.isBlocksSelecting) {
                return;
            }
            this.isBlockRemoving = !this.isBlockRemoving;
            this.isBlocksSelecting = false;
        },
        startResettingProcess() {
            if (this.isBlocksSelecting || this.isBlockRemoving) {
                return;
            }
            this.timeoutId = setTimeout(() => {
                this.reset();
            }, 1500);
        },
        reset() {
            this.shownModules.splice(0, this.shownModules.length);
            this.layout.splice(0, this.layout.length);
        },
        clearTimeoutId() {
            if (!this.timeoutId) {
                return;
            }
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        },
        setPlacement(name, index) {
            const foundIndex = this.layout.findIndex(({ i }) => i === index);
            const newLayout = { ...this.layout };
            newLayout[foundIndex].placement = name;
            this.layout = newLayout;
        },
        setPair(pair, index) {
            const foundIndex = this.layout.findIndex(({ i }) => i === index);
            const newLayout = { ...this.layout };
            newLayout[foundIndex].pair = pair;
            this.layout = newLayout;
        },
        getGridItemCoordinates(index) {
            if (this.isNew) {
                return { x: this.$store.state.Interface.defaultPresetsPagesGrid.workspace.lg[index].x, y: this.$store.state.Interface.defaultPresetsPagesGrid.workspace.lg[index].y };
            }
            return { x: this.$store.state.Interface.pageGrids.workspace.lg[index].x, y: this.$store.state.Interface.pageGrids.workspace.lg[index].y };
        },
        toggleTooltip() {
            if (!this.isBlocksSelecting && !this.isBlockRemoving) {
                this.showTooltip = true;
            }
        },
        async selectPreset(preset) {
            await this.$store.dispatch(doPreset({ preset, page: this.$router.currentRoute.name ?? undefined }));
            await this.addModules(true);
        },
        setPresetIndex(index) {
            if (this.presetQueryParam) {
                const query = { ...this.$route.query };
                delete query.preset;
                this.$router.replace({ query });
            }
            this.activePresetIndex = index;
            this.showPresetsDropdown = false;
        },
        getResizableItemSize(index) {
            const { w, h } = this.layout.find(({ i }) => i === index);
            return {
                w,
                h,
            };
        },
        closePresetsDropdown(e) {
            const path = composedPath(e.target);
            if (!path.some((node) => node.classList && Array.from(node.classList).includes('presetsDropdown'))) {
                this.showPresetsDropdown = false;
            }
        },
    },
    async mounted() {
        document.addEventListener('click', this.closePresetsDropdown);
        const { isNew, preset } = this.$route.query;
        if (isNew && isNew === 'true') {
            this.isNew = true;
        }
        if (preset) {
            const { data: newPreset } = await SettingsApi.getPreset(new PresetRequest({
                id: Number(preset),
                type: 'WORKSPACE',
            }));
            const layer: any[] = [];
            const { data } = newPreset;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            data.forEach(({ x, y, w, h, i, name, placement, pair }) => {
                layer.push({ x, y, w, h, i, name, placement, pair });
            });
            this.$store.state.Interface.defaultPresetsPagesGrid[PRESET_TYPES_TO_LAYOUTS_NAMES[PRESETS_TYPES[router.currentRoute.name!]]].lg = layer;

            this.activePresetIndex = this.fullPresetsList.findIndex(({ id }) => id === Number(preset));
            this.isBlocksSelecting = false;
        }

        if (this.shownModulesLength === 0) {
            this.isBlocksSelecting = true;
        }

        if (this.activePreset && !this.isNew) {
            this.activePresetIndex = this.fullPresetsList.findIndex(({ name }) => name === this.activePreset);
        }

        window.addEventListener('mouseup', () => {
            this.clearTimeoutId();
        });

        window.addEventListener('click', () => {
            if (this.showTooltip) {
                this.showTooltip = false;
            }
        });
        await this.$store.dispatch(getFavoriteAssetPairsList(undefined));
    },
    beforeDestroy() {
        document.removeEventListener('click', this.closePresetsDropdown);
    },
    watch: {
        currentBreakpoint() {
            this.addModules(true);
        },
        isNewQueryParam(value) {
            if (value && value === 'true' && !this.presetQueryParam) {
                this.layout = this.$store.getters.defaultPageGrid(gridPages.WORKSPACE, 'lg');
            }
        },
        isBlocksSelecting(value) {
            if (!value) {
                this.clearData();
            }
        },
        layout(val) {
            const needSort = val.some(({ i }, index) => {
                return index !== 0 && i < val[index - 1].i;
            });
            if (needSort) {
                this.shownModules = val
                    .sort(({ i: iOne }, { i: iTwo }) => iOne - iTwo)
                    .map(({ name }) => name);
            }
        },
        activePreset(value, oldValue) {
            if (this.presetQueryParam && oldValue) {
                const query = { ...this.$route.query };
                delete query.preset;
                this.$router.replace({ query });
            }
            // manage buttons
            this.isBlockRemoving = false;
            if (value) {
                this.isBlocksSelecting = false;
            }

            // manage workspace presets list
            if (!value) {
                this.activePresetIndex = 0;
            } else if (!this.isNew) {
                this.activePresetIndex = this.fullPresetsList.findIndex(({ name }) => name === value);
            } else if (oldValue) {
                this.activePresetIndex = this.fullPresetsList.findIndex(({ name }) => name === value);
            } else {
                this.isBlocksSelecting = true;
            }
        },
        shownModulesLength(value) {
            this.isBlocksSelecting = value === 0;
        },
        fullPresetsList(value) {
            if (value && value.length > 0 && this.presetQueryParam) {
                this.activePresetIndex = this.fullPresetsList.findIndex(({ id }) => id === Number(this.presetQueryParam));
            }
        },
        async activePresetIndex(index) {
            if (!this.presetQueryParam) {
                await this.selectPreset(this.fullPresetsList[index]);
            }
        },
    },
});
