
import Vue from 'vue';

import OrdersHistory from 'Modules/OrdersHistory/OrdersHistory.vue';
import TakeProfitStopLossModal from 'Modules/OrdersHistory/components/TakeProfitStopLossModal.vue';

import EmptyHeader from '../EmptyHeader.vue';

export default Vue.extend({
    components: {
        TakeProfitStopLossModal,
        EmptyHeader,
    },
    computed: {
        component() {
            return OrdersHistory;
        },
    },
});
