
import Vue from 'vue';

import { timeConstants } from 'Config/timeConstants';

import ChooseDate from '../ChooseDate.vue';

import TradesPerExchangeDonut from './TradesPerExchangeDonut.vue';

export default Vue.extend({
    props: {
        range: {
            type: Object,
            default: () => ({
                start: new Date(Date.now() - timeConstants.MONTH),
                end: new Date(),
            }),
        },
    },
    components: {
        ChooseDate,
    },
    computed: {
        component() {
            return TradesPerExchangeDonut;
        },
    },
});
