
import Vue from 'vue';

import MultitransfersModal from 'Modules/Transfer/components/MultitransfersModal.vue';
import MultitransfersConfirmModal from 'Modules/Transfer/components/MultitransfersConfirmModal.vue';

import EmptyHeader from '../EmptyHeader.vue';

import Transfer from './Transfer.vue';

export default Vue.extend({
    components: {
        MultitransfersModal,
        MultitransfersConfirmModal,
        EmptyHeader,
    },
    data() {
        return {
            confirmData: {},
        };
    },
    computed: {
        component() {
            return Transfer;
        },
    },
});
