
import Vue from 'vue';

import EmptyHeader from '../EmptyHeader.vue';

import TransferHistory from './TransferHistory.vue';

export default Vue.extend({
    components: {
        EmptyHeader,
    },
    computed: {
        component() {
            return TransferHistory;
        },
    },
});
